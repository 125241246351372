/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';
import '../css/style.bundle.css';
import '../css/datatables.bundle.css';

import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import '../css/animate.css';
import '../css/line-awesome/css/line-awesome.css';
import '../css/flaticon/flaticon.css';
import '../css/flaticon2/flaticon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import '../css/aside/dark.css';
import '../css/brand/dark.css';
import '../css/header/base/light.css';
import '../css/header/menu/light.css';

import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'js-cookie';
import 'moment';
import 'tooltip';
import 'perfect-scrollbar';
import 'sticky-js';

import 'jquery-form';
import 'jquery-validation';
import 'block-ui';
import 'bootstrap-select';
import 'bootstrap-timepicker';
import 'bootstrap-datetime-picker';
import 'bootstrap-daterangepicker';
import 'bootstrap-datepicker';
import 'datatables.net';
//import 'datatables.net-buttons';
// import 'bootstrap/js/dist/dropdown';
//import 'sweetalert2';

import './jquery-validation.init';
import './bootstrap-timepicker.init';
import './bootstrap-datetimepicker';
import './bootstrap-daterangepicker';
import './form-controls';
import './datatables.bundle';
/*import './datatables/dataTables.buttons.min';
import './datatables/buttons.flash.min';
import './datatables/jszip.min';
import './datatables/buttons.html5.min';
import './datatables/buttons.print.min';*/

var Utils = {
    parents: function (elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {
                    }
                    return i > -1;
                };
        }

        // Set up a parent array
        var parents = [];

        // Push each parent element to the array
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (selector) {
                if (elem.matches(selector)) {
                    parents.push(elem);
                }
                continue;
            }
            parents.push(elem);
        }

        // Return our parent array
        return parents;
    },

    trim: function(string) {
        return string.trim();
    },

    hasClass: function(el, className) {
        if (!el) {
            return;
        }

        return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
    },

    addClass: function(el, className) {
        if (!el || typeof className === 'undefined') {
            return;
        }

        var classNames = className.split(' ');

        if (el.classList) {
            for (var i = 0; i < classNames.length; i++) {
                if (classNames[i] && classNames[i].length > 0) {
                    el.classList.add(Utils.trim(classNames[i]));
                }
            }
        } else if (!Utils.hasClass(el, className)) {
            for (var i = 0; i < classNames.length; i++) {
                el.className += ' ' + Utils.trim(classNames[i]);
            }
        }
    },

    get: function(query) {
        var el;

        if (query === document) {
            return document;
        }

        if (!!(query && query.nodeType === 1)) {
            return query;
        }

        if (el = document.getElementById(query)) {
            return el;
        } else if (el = document.getElementsByTagName(query)) {
            return el[0];
        } else if (el = document.getElementsByClassName(query)) {
            return el[0];
        } else {
            return null;
        }
    }
};

$(document).ready(function () {
    var activeTab = $('#activeTab').val() || window.location.pathname;
    if (activeTab) {
        var item = $('a[href="' + activeTab + '"]').closest('.kt-menu__item')[0];

        var parents = Utils.parents(item, '.kt-menu__item--submenu') || [];
        for (var i = 0, len = parents.length; i < len; i++) {
            Utils.addClass(Utils.get(parents[i]), 'kt-menu__item--open');
        }
        Utils.addClass(Utils.get(item), 'kt-menu__item--active');
    }
});
