// Class definition

var KTBootstrapDatetimepicker = function () {
    
    // Private functions
    var demos = function () {

        $.fn.datetimepicker.dates['fr'] = {
            days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            daysShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
            daysMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
            months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            monthsShort: ["Janv.", "Févr.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
            today: "Aujourd'hui",
            monthsTitle: "Mois",
            clear: "Effacer",
            weekStart: 1,
            meridiem: ''
        };

        $('.edition_pilotage_dateTeacher').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-right',
            format: 'dd/mm/yyyy hh:ii',
            minuteStep: 30,
            startDate: new Date(),
            language: 'fr'
        });

        $('.edition_pilotage_dateSearcher').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'top-right',
            format: 'dd/mm/yyyy hh:ii',
            minuteStep: 30,
            startDate: new Date(),
            language: 'fr',
        });

        $('.edition_pilotage_dateInterventionsChoice').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'top-right',
            format: 'dd/mm/yyyy hh:ii',
            minuteStep: 30,
            startDate: new Date(),
            language: 'fr',
        });

        /*$('.detail_intervention_date_intervention').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'top-right',
            format: 'dd/mm/yyyy',
            minuteStep: 30,
            startDate: new Date(),
            language: 'fr',
        });*/


    };

    return {
        // public functions
        init: function() {
            demos();
        }
    };
}();

jQuery(document).ready(function() {
    KTBootstrapDatetimepicker.init();
});
