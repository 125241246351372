// Class definition

var KTBootstrapDaterangepicker = function () {

    // Private functions
    var demos = function () {

        $('#kt_daterangepicker_teacher').daterangepicker({
            buttonClasses: ' btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            language: 'fr',
            autoUpdateInput: false,
            minDate: moment(),

            timePicker: true,
            timePickerIncrement: 30,
            timePicker24Hour: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Appliquer',
                cancelLabel: 'Annuler',
                fromLabel: 'De',
                toLabel: 'A',
                customRangeLabel: 'Custom',
                weekLabel: 'S',
                daysOfWeek: ["D", "L", "Ma", "Me", "J", "V", "S"],
                monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                firstDay: 1
            }
        }, function (start, end, label) {
            $('#kt_daterangepicker_teacher .form-control').val(start.format('DD/MM/YYYY HH:mm') + ' - ' + end.format('DD/MM/YYYY HH:mm'));
        });

        $('#kt_daterangepicker_interventions').daterangepicker({
            buttonClasses: ' btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            language: 'fr',
            autoUpdateInput: false,
            minDate: moment(),

            timePicker: true,
            timePickerIncrement: 30,
            timePicker24Hour: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Appliquer',
                cancelLabel: 'Annuler',
                fromLabel: 'De',
                toLabel: 'A',
                customRangeLabel: 'Custom',
                weekLabel: 'S',
                daysOfWeek: ["D", "L", "Ma", "Me", "J", "V", "S"],
                monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                firstDay: 1
            }
        }, function (start, end, label) {
            $('#kt_daterangepicker_interventions .form-control').val(start.format('DD/MM/YYYY HH:mm') + ' - ' + end.format('DD/MM/YYYY HH:mm'));
        });

    };

    return {
        // public functions
        init: function () {
            demos();
        }
    };
}();

jQuery(document).ready(function () {
    KTBootstrapDaterangepicker.init();
});
